import { css, Global } from '@emotion/react';
import React from 'react';
import styled from 'styled-components';
import SEO from '../components/organisms/seo';

const NotFoundPage = () => (
  <>
    <Global
      styles={css`
        .main-wrapper {
          background: var(--b);
          color: var(--w);
        }
      `}
    />
    <SEO siteTitle="404" />
    <Styled404 className="hl">
      <p>Oh no! This page doesn't exist.</p>
    </Styled404>
  </>
);

const Styled404 = styled.div`
  min-height: 100vh;

  p {
    padding-top: var(--sp-1-3);
    padding-left: var(--sp-1-3);
  }
`;

export default NotFoundPage;
